import React from "react"

import { btn, card, cards, projects, header } from "./projects.module.scss"

const Projects = () => {
  return (
    <section id="projects" className={projects}>
      <div className={header}>
        <h1>Projects</h1>
        <p>
          Below is a sample of my own personal side projects with GitHub repo
          links.
        </p>
      </div>
      <div className={cards}>
        <div className={card}>
          <h1>Diamond Rock Fuel Solutions</h1>
          <p>
            Software Design course project web application to generate pricing
            for fuel orders. Built with the Django and Bootstrap.
          </p>
          <a
            className={btn}
            target="_blank"
            href="https://github.com/VisualizerMk2/diamond-rock-fuel-solutions-project"
          >
            GitHub
          </a>
        </div>
        <div className={card}>
          <h1>GameCat</h1>
          <p>
            Single page web app for cataloging personal video game collection.
            Built with React and Redux for front end UI and state management.
            Served via a simple Express server on Heroku. Data stored using
            Firebase. Unit tested with Jest and Enzyme.
          </p>
          <a
            className={btn}
            target="_blank"
            href="https://github.com/VisualizerMk2/game-cat"
          >
            GitHub
          </a>
        </div>
        <div className={card}>
          <h1>Clutch City Stats</h1>
          <p>
            Single page web app for data visualization of basketball player
            stats. Front end built with React using the new hooks API. Data
            visualization generated by D3js.
          </p>
          <a
            className={btn}
            target="_blank"
            href="https://github.com/VisualizerMk2/clutch-city-stats"
          >
            GitHub
          </a>
        </div>
      </div>
    </section>
  )
}

export default Projects
